.pipe a {
    /*color: rgba(0, 0, 0, 0.5) !important;*/

    &:hover, &:focus {
        color: #007bffb0 !important;
    }
}

div {
    &.pipe {
        display: inline;
        padding: 10px 3.17px;
        margin: 5px 2.23px;
        border-radius: 10px;

        &:hover {
            padding: 12px 4px;
            margin: 5px 1.23px;
        }

        &.text-danger {
            background-color: #e74c3c;
        }

        &.black {
            background-color: #707070;
        }

        &.text-success {
            background-color: #36d576;
        }

        &.text-primary {
            background-color: #0870a8;
        }

        &.text-warning {
            background-color: #f1c40e
        }
    }

    &.red {
        background-color: #e74c3c;
    }

    &.black {
        background-color: #707070;
    }

    &.green {
        background-color: #36d576;
    }

    &.yellow {
        background-color: #f1c40e
    }
}

b, i.fas {
    &.green {
        color: #36d576;
    }

    &.yellow {
        color: #f1c40e;
    }

    &.red {
        color: #e74c3c;
    }
}


.wrap {
    text-align: center;
    margin: 0 20px;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 10%;
        border-top: 1px solid #707070;
        background: #707070;
        width: 80%;
        transform: translateY(-50%);
    }
}

.links {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.dot {
    background: white;
    padding: 5px
}

.title {
    font-size: 1.3em;
}

.pipe {
    &:hover span {
        visibility: visible;
        opacity: 1;
        transform: translateX(-40%) translateY(0px);
    }


    span {
        position: absolute;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 4px;
        margin: 8.2px 0 0;
        box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.25);
        border: solid 1px rgba(168, 172, 177, 0.25);
        background-color: #fff;
        visibility: hidden;
        opacity: 0;
        width: max-content;
        transition: 0.26s;
        z-index: 10;

        &.lg {
            font-size: 12px;
            padding-bottom: 0;

            p {
                margin: 0 0 9px;
                min-width: 25em;
                font-weight: 500;
                padding: 7px 28px 6px 15px;
                font-size: 12px;
                background-color: rgba(163, 158, 158, 0.25);
            }
        }

        &.sup {
            bottom: 40px;
            transform: translateX(-40%);
        }

        &.inf {
            bottom: -75px;
            transform: translateX(-40%);
        }

        &.lg.inf {
            bottom: -185px;
            transform: translateX(-40%);
        }

        li.first {
            list-style: none;
            padding-left: 15px;

            ul {
                margin-top: 12px;
            }
        }

        li.second {
            list-style: none;
            position: relative;
            right: 30px;
            margin-bottom: 12px;
        }

        b {
            font-size: 14px;
            display: block;
            font-weight: 600;
            font-family: Rubik;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            margin-bottom: 10px;
            color: #333;
        }

        p {
            margin: 5px 0 0 0;
            font-family: Rubik;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #333;
        }
    }
}

@media only screen and (max-width: 1199px) {
    div {
        &.pipe {
            padding: 10px 2.4px;
            margin: 5px 2px;

            &:hover {
                padding: 12px 4px;
                margin: 5px 0.25px;
            }
        }
    }
}


@media only screen and (max-width: 1025px) {
    div {
        &.pipe {
            padding: 10px 3px;
            margin: 5px 1.4px;

            &:hover {
                padding: 12px 4px;
                margin: 5px 0.25px;
            }
        }
    }
}


@media only screen and (max-width: 991px) {
    div {
        &.pipe {
            padding: 10px 2.12px;
            margin: 5px .95px;

            &:hover {
                padding: 12px 2.7px;
                margin: 5px 0.25px;
            }
        }
    }
}


@media only screen and (max-width: 768px) {
    div {
        &.pipe {
            padding: 10px 2px;
            margin: 5px 1.07px;

            &:hover {
                padding: 12px 2.7px;
                margin: 5px 0.25px;
            }
        }
    }
}


@media only screen and (max-width: 767px) {
    div {
        &.pipe {
            padding: 10px 1.5px;
            margin: 5px .57px;

            &:hover {
                padding: 12px 2.7px;
                margin: 5px 0.25px;
            }
        }
    }
}


@media only screen and (max-width: 576px) {
    div {
        &.pipe {
            padding: 10px 1.51px;
            margin: 5px .57px;

            &:hover {
                padding: 12px 2.7px;
                margin: 5px 0.25px;
            }
        }
    }
}




@media only screen and (max-width: 426px) {
    div {
        &.pipe {
            padding: 10px 1.50px;
            margin: 5px .35px;

            &:hover {
                padding: 12px 1.7px;
                margin: 5px 0.1px;
            }
        }
    }

    .links {
        .dot:not(.extremity) {
            margin: auto;
        }

        .dot.extremity {
            display: none;
        }
    }

    .wrap {
        &:before {
            visibility: hidden;
            opacity: 0;
        }
    }
}



@media only screen and (max-width: 376px) {
    div {
        &.pipe {
            padding: 10px 1.25px;
            margin: 5px .33px;

            &:hover {
                padding: 12px 1.4px;
                margin: 5px 0.09px;
            }
        }
    }
}



@media only screen and (max-width: 320px) {
    div {
        &.pipe {
            padding: 10px 1.05px;
            margin: 5px .24px;

            &:hover {
                padding: 12px 1.2px;
                margin: 5px 0.04px;
            }
        }
    }
}
